<i18n>
{
  "en": {
    "browser_title": "High Speed Plan",
    "highspeed": "High Speed Plan"
  },
  "ja": {
    "browser_title": "ハイスピードプラン",
    "highspeed": "ハイスピードプラン"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="highspeed" v-if="!newXPEligible">
      <div class="block-header">
        <div class="container">
          <h1>{{ $t('highspeed' )}}</h1>
        </div>
      </div>

      <section id="highspeed-intro" v-if="locale == 'ja'">
        <div class="container">
          <div class="section is-wide">
            <div class="highspeed-tagline">ネットワーク通信を最適化する最新機器の導入によりダウンロードスピードが<em>最大10倍UP！</em><small>(当社比)</small></div>
            <div class="section is-dense">
              <div class="button-box is-centered">
                <a class="button is-outlined is-large is-rounded is-color-highspeed" href="#highspeed-test" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }">速度テストで違いを体験<i class="iconf-keyboard-arrow-down is-right"></i></a>
                <a class="button is-outlined is-large is-rounded is-color-highspeed" href="#highspeed-signup" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }">プランに申し込む<i class="iconf-keyboard-arrow-down is-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="highspeed-intro" v-if="locale == 'en'">
        <div class="container">
          <div class="section is-wide">
            <div class="highspeed-tagline">Now You Can Increase Your Download Speed <em>UP TO 10 TIMES!</em></div>
            <div class="section is-dense">
              <div class="button-box is-centered">
                <a class="button is-outlined is-large is-rounded is-color-highspeed" href="#highspeed-test" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }">Speed Test<i class="iconf-keyboard-arrow-down is-right"></i></a>
                <a class="button is-outlined is-large is-rounded is-color-highspeed" href="#highspeed-signup" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }">Sign Up Now<i class="iconf-keyboard-arrow-down is-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="highspeed-test" v-if="locale == 'ja'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">速度テスト</h2>
            <p>ハイスピードプランをご購入の前に、まずは速度テストでご確認下さい。</p>
            <h3>テスト方法</h3>
            <ol class="has-style is-decimal">
              <li>下記「速度テストを開始」ボタンを押してテスト画面へ移動します。 (※新しいタブまたはウィンドウが開きます)</li>
              <li>テスト画面にて「Normal」を選択しスタートボタンを押すと、現在ご利用中の回線速度が測定できます。</li>
              <li>次に「High」を選択しスタートボタンを押すと、ハイスピードプランご利用時の速度が測定できますので、2の現在の回線速度と比較してお確かめください。</li>
            </ol>
            <div class="section">
              <a class="button is-filled is-large is-rounded is-color-highspeed" href="https://speedtest.d2pass.com/" target="_blank" rel="noopener">速度テストを開始</a>
            </div>
            <h3>注意事情</h3>
            <ul class="has-style is-disc">
              <li>このテストはベストエフォート型 (規格上の最大値) ですので、購入後実際の通信速度はご利用の地域や回線状況やピーク時等の混雑状況によって変動する可能性があります。スピードテストは比較測定の目安としてご利用ください。</li>
              <li>接続にプロキシを使用している場合、測定ができない場合があります。</li>
              <li>お使いのデバイスの処理速度によって測定結果に若干の誤差が生じる場合があります。</li>
              <li>JavaScript及びCookieが使用できる状態でご利用ください。</li>
              <li>速度測定が途中で停止する場合、接続が不安定で切断されている可能性があります。回線の状況を確認頂き再度測定し直してください。</li>
            </ul>
          </div>
        </div>
      </section>

      <section id="highspeed-test" v-if="locale == 'en'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">Speed Test</h2>
            <p>Please check speed test before purchasing High Speed Plan.</p>
            <h3>How to test</h3>
            <ol class="has-style is-decimal">
              <li>Click the "Start Speed Test" button below to go to the test page. (*New tab/window will open)</li>
              <li>First, select "Normal" on the test page and press start button, you can test your current download speed.</li>
              <li>Next, select "High" and press start button, you can simulate the speed with High Speed Plan. Please compare with the test result from step 2.</li>
            </ol>
            <div class="section">
              <a class="button is-filled is-large is-color-highspeed" href="https://speedtest.d2pass.com/" target="_blank" rel="noopener">Start Speed Test</a>
            </div>
            <h3>Notes</h3>
            <ul class="has-style is-disc">
              <li>Since this test is a best-effort type, actual download speed after purchase may fluctuate depending on the area of use, congestion situation such as line conditions and peak time. Please use the speed test as a comparative measurement.</li>
              <li>Speed test may not be available if you are using proxy.</li>
              <li>Test results may slightly fluctuate depending on processing speed of your devices.</li>
              <li>Please have JavaScript and cookies enabled.</li>
              <li>If the speed test stops halfway, please check your network condition and try again.</li>
            </ul>
          </div>
        </div>
      </section>

      <section id="highspeed-signup" v-if="locale == 'ja'">
        <div class="container">
          <div class="section is-wide">
            <h2 class="is-secondary">お申し込み</h2>
            <span v-if="!newXPEnabled">
              <package-table currency="USD" package-type="highspeed" />
            </span>
            <span v-else>
              <div class="section">
                <div class="message-box is-warning">
                  <p>ハイスピードプランの販売は終了いたしました。</p>
                </div>
              </div>
            </span>
            <div class="hs-note is-note">
              <ul class="has-style is-disc">
                <li>ハイスピードプランの有効期限は、パコパコママの会員期間終了に伴い自動で終了します。</li>
                <li>ハイスピードプランは自動継続方式となっておりますので、30日ごとに自動課金されます。</li>
                <li>ハイスピードプランの退会をご希望の場合はD2Passマイアカウントからの退会手続きが必要です。<a href="https://www.d2pass.com/account/download/sitelist?NetiFL=1" target="_blank">退会手続きはこちら &raquo;</a></li>
              </ul>
            </div>

          </div>
        </div>
      </section>
      <section id="highspeed-signup" v-if="locale == 'en'">
        <div class="container">
          <div class="section is-wide">
            <div class="heading">
              <h2>Sign Up</h2>
            </div>
            <span v-if="!newXPEnabled">
              <package-table currency="USD" package-type="highspeed" />
            </span>
            <span v-else>
              <div class="section">
                <div class="message-box is-warning">
                  <p>Highspeed Plan is no longer available.</p>
                </div>
              </div>
            </span>
            <ul class="has-style is-disc">
              <li>High Speed Plan will be automatically terminated when your membership expires.</li>
              <li>High Speed Plan will be automatically renewed and charged every 30 days.</li>
              <li>If you want to cancel your High Speed Plan, you will need to cancel from D2Pass My Account page. <a href="https://www.d2pass.com/account/download/sitelist?NetiFL=1&lang=en" target="_blank" rel="noopener">Click here <i class="iconf-ex-link"></i></a> to cancel.</li>
            </ul>
          </div>
        </div>
      </section>

    </div><!-- /#highspeed -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import PackageTable from '@/components/highspeed/packageTable.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'package-table': PackageTable,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  created() {
    // non-reactive consts
    this.appHeaderHeight = APP_CONFIG.site.headerHeight;

    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEnabled() {
      return (process.env.VUE_APP_NEW_XP_ENABLED === 'true');
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_highspeed.scss';
</style>
